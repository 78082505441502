import React from 'react';
import { Helmet } from 'react-helmet-async';
import { organization } from '../../configs/config_settings.json';

export default function MetaDecorater({
  title,
  quote,
  description,
  imageUrl,
  hashtag,
}) {
  console.log(title, description);
  let currentUrl = window.location.href;

  return (
    <Helmet prioritizeSeoTags>
      <title>{title}</title>

      <meta name='description' content={description} />

      <meta property='og:title' content={title} />
      <meta property='og:quote' content={quote} />
      <meta property='og:hashtag' content={hashtag} />
      <meta
        property='og:image'
        // content={'https://staging1.digitaltree.icareus.com' + imageUrl}
        content={imageUrl}
      />
      <meta
        property='og:url'
        content='https://staging1.digitaltree.icareus.com/'
      />

      <meta property='og:image:width' content='400' />
      <meta property='og:image:height' content='300' />

      <meta property='og:description' content={description} />
      <meta name='twitter:card' content='summary_large_image' />
      <meta name='twitter:title' content={title} />
      <meta name='twitter:description' content={description} />
      <meta name='twitter:image' content={imageUrl} />
    </Helmet>
  );
}
