import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { hydrate, render } from 'react-dom';

import { BrowserRouter as Router } from 'react-router-dom'; // HashRouter, basename //app to router // BrowserRouter
import StateHolder from './contexts/StateHolder';
import ContextFunctions from './contexts/ContextFunctions';
import './i18n/config';
import ScrollToTop from './components/ScrollToTop';
import { CookiesProvider } from 'react-cookie';
import { HelmetProvider } from 'react-helmet-async';

// I got this same error trying to render an SPA with no other external files/data to load - just a simple single page HTML. It seems to be an error caused by the "homepage" entry in package.json. I had:

// "homepage": ".",
// which was enough to tip it over. Removing that line solved the problem, but, of course, it was there for a reason.

const helmetContext = {};

console.log = function () {}; // Disable all console.logs from console

const app = (
  <React.StrictMode>
    <Router>
      <CookiesProvider>
        <HelmetProvider context={helmetContext}>
          <StateHolder>
            <ContextFunctions>
              <ScrollToTop />
              <App />
            </ContextFunctions>
          </StateHolder>
        </HelmetProvider>
      </CookiesProvider>
    </Router>
  </React.StrictMode>
);

const rootElement = document.getElementById('root');
if (rootElement.hasChildNodes()) {
  hydrate(app, rootElement);
} else {
  render(app, rootElement);
}

// ReactDOM.render(app, document.getElementById('root'));
