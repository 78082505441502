import React from 'react';

import { useEffect, useState } from 'react';

import * as classes from './TrendingTopShows.module.css';
import Carousel from 'react-multi-carousel';

import axios from 'axios';
import { useMyContext } from '../../../../../contexts/StateHolder';
import CategoryTitle from '../../../CategoryTitles/CategoryTitle';
import CategoryTitle2 from '../../../CategoryTitles/CategoryTitle2/CategoryTitle2';
import { getTrendingShow } from '../../../../../scripts/dataHandlers';
import RenderItem from '../../../RenderItems/RenderItem';
import { createToken } from '../../../../../scripts/utils';

export default function TrendingTopShows(props) {
  const { organizationId, key, language, user } = useMyContext();

  const components = {
    CategoryTitle,
    CategoryTitle2,
  };

  const {
    slickSettings,
    routes,
    itemImageComponent,
    itemTitleComponent,
    categoryTitleComponent,
    assetProperty,
  } = props.settings;

  const [allSubCategories, setAllSubCategories] = useState(null);
  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    const getData = async () => {
      // here in case of geetting top shows we have to pass groupItemId as empty string
      const token = createToken(organizationId, '', key);

      const res = await getTrendingShow(organizationId, token, 30, 10);
      setAllSubCategories(res);
      console.log(`res series`, res);
    };
    organizationId && key && getData();

    return () => source.cancel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language, user]);

  const CategoryTitleComponent = components[categoryTitleComponent];

  return (
    <div className={classes.allSeriesContainer}>
      {allSubCategories && allSubCategories.length > 0 && (
        <div className={`${classes.ListAllCategories} font-500`}>
          <CategoryTitleComponent
            id={'123456789098765421'}
            title={props.titleName}
            routes={routes}
            item={null}
            showSerieViewAll={true}
            isSerie={true}
            showTitle={true}
          />
          <Carousel {...slickSettings} infinite={false}>
            {allSubCategories.map((item) => {
              item.isSerie = true;
              item.series = item.groupItems;
              item.serieId = item.id;
              item.listSerieItem = true;
              return (
                <RenderItem
                  key={`${item.id}`}
                  item={item}
                  imageType={'coverImage'}
                  pushRoute={`/${routes.serieRoute}/${organizationId}/${item.id}`} //${item.serieId}/${item.series[0].id}`}
                  itemImageComponent={itemImageComponent}
                  itemTitleComponent={itemTitleComponent}
                  // textStyle={{ textAlign: 'center' }}
                  renderCategory={true}
                  showPlayIcon={true}
                  hidePlayButton={true}
                  isSerie={true}
                  hideDescription={props.hideDescription}
                  showCategoryName={props.showCategoryName}
                />
              );
            })}
          </Carousel>
        </div>
      )}
    </div>
  );
}
